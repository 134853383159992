
const routes = [
  { path: '/category/:catname', name: 'category', component: () => import(/* webpackChunkName: "category" */ './pages/Category') },
  { path: '/department/:catname', name: 'department', component: () => import(/* webpackChunkName: "department" */ './pages/Department') },
  { path: '/product/details/:pdname', name: 'productdetails', component: () => import(/* webpackChunkName: "productdetails" */ './pages/Product') },
  { path: '/about', name: 'about', component: () => import(/* webpackChunkName: "about" */ './pages/About') },
  { path: '/contact', name: 'contact', component: () => import(/* webpackChunkName: "contact" */ './pages/Contact') },
  { path: '/faq', name: 'faq', component: () => import(/* webpackChunkName: "faq" */ './pages/FAQ') },
  { path: '/services', name: 'services', component: () => import(/* webpackChunkName: "shop" */ './pages/Services') },
  { path: '/service/requests/:catname', name: 'request', component: () => import(/* webpackChunkName: "request" */ './pages/Request') },
  { path: '/account/dashboard', name: 'dashboard', component: () => import(/* webpackChunkName: "dashboard" */ './pages/Dashboard') },
  { path: '/account/register', name: 'register', component: () => import(/* webpackChunkName: "register" */ './pages/Register') },
  { path: '/account/login', name: 'login', component: () => import(/* webpackChunkName: "login" */ './pages/Login') },
  { path: '/account/profile', name: 'profile', component: () => import(/* webpackChunkName: "profile" */ './pages/Profile') },
  { path: '/others/:pagename', name: 'others', component: () => import(/* webpackChunkName: "others" */ './pages/Others') },
  // { path: '/order-success', name: 'ordersuccess', component: () => import(/* webpackChunkName: "ordersuccess" */ './pages/order-success.vue') },
  { path: '/account/dashboard/view-service', name: 'orderview', component: () => import(/* webpackChunkName: "orderview" */ './pages/OrderView.vue') },
  { path: '/account/dashboard/service-payment', name: 'servicepayment', component: () => import(/* webpackChunkName: "servicepayment" */ './pages/ServicePayment.vue') },
  { path: '/category/details/:catname', name: 'categorydetails', component: () => import(/* webpackChunkName: "productdetails" */ './pages/CategoryDetails') },
  { path: '/account/resend-otp', name: 'resend-otp', component: () => import(/* webpackChunkName: "resend-otp" */ './pages/ResedOtp') },
  { path: '/account/setpassword/:clcode', name: 'setpassword', component: () => import(/* webpackChunkName: "resend-otp" */ './pages/SetPassword') },
  { path: '/account/ordersuccess/:order_id', name: 'ordersuccess', component: () => import(/* webpackChunkName: "resend-otp" */ './pages/OrderSuccess') },
  { path: '/account/verify-account', name: 'verify-account', component: () => import(/* webpackChunkName: "verify-account" */ './pages/VerifyAccount') },
  {
    path: '/auth/:provider/callback', name: 'sociallogin', component: {
      template: '<div class="auth-component"></div>'
    }
  },
];

export default routes;